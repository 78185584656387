import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../auth/AuthProvider";
import * as Popover from "@radix-ui/react-popover";
import Image from "next/image";
import { useRouter } from "next/router";
import algoliasearch from "algoliasearch/lite";
import { load } from "@fingerprintjs/botd";
import {
  InstantSearch,
  Index,
  Configure,
  useInstantSearch,
  useHits,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import TownHit from "../search/Hit/TownHit";
import ListerHit from "../search/Hit/ListerHit";
import PropertyHit from "../search/Hit/PropertyHit";
import { CgProfile } from "react-icons/cg";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FiSearch } from "react-icons/fi";
import cookie from "js-cookie";
import { onValue, orderByChild, query, ref } from "firebase/database";
import { useClickOutside } from "@react-hooks-library/core";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { AiOutlineMenu } from "react-icons/ai";
// import dynamic from "next/dynamic";

import { rtdb } from "../../scripts/firebase";
import { capture } from "../../utils/analytics/capture";
// const rtdb = dynamic(() => import("../../scripts/firebase"));

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_SEARCH_APP_ID,
  process.env.NEXT_PUBLIC_SEARCH_API_KEY
);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      // return Promise.resolve({
      //   results: requests.map(() => ({
      //     hits: [],
      //     nbHits: 0,
      //     nbPages: 0,
      //     page: 0,
      //     processingTimeMS: 0,
      //     hitsPerPage: 0,
      //     exhaustiveNbHits: false,
      //     query: "",
      //     params: "",
      //   })),
      // });
    }
    return algoliaClient.search(requests);
  },
};

function MainNav({
  openNav,
  setOpenNav,
  open,
  setOpen,
  active,
  setActive,
  props,
}) {
  const contextAuth = useAuth();
  const user = contextAuth.user;
  const loadingUser = contextAuth.loading;
  const claims = contextAuth.claims;
  const router = useRouter();
  const signOut = contextAuth.signout;
  const [loadingMessages, setLoadingMessages] = useState(true);
  let townProp;
  const [popOpen, setPopOpen] = useState(false);
  const [allUnread, setAllUnread] = useState(0);
  const searchRef = useRef(null);

  // console.log(props?.props?.children)

  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [sendingResetLink, setSendingResetLink] = useState(false);

  const [emailVerifiedText, setEmailVerifiedText] =
    useState("Email Unverified");

  const [resultsOpen, setResultsOpen] = useState(true);

  const messageRef = query(
    ref(rtdb, "Users/" + user?.uid + "/PropertyMessages"),
    orderByChild("lastMessage/time")
  );

  useEffect(() => {
    let reff;
    if (user) {
      reff = onValue(
        messageRef,
        async (snapshot) => {
          let c = 0;
          snapshot.forEach(function (child) {
            c = c + child.val().unreadCountUser;
          });
          setAllUnread(c);
          setLoadingMessages(false);
        },
        {
          onlyOnce: false,
        }
      );
    }

    return () => {
      if (user) {
        reff();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMessages, user]);

  ///
  //
  const [bot, setBot] = useState(false);
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const handleRecaptcha = useCallback(() => {
  //   if (!executeRecaptcha) {
  //     console.log("Execute recaptcha not yet available");
  //     return;
  //   }
  //   executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
  //     fetch("/api/recap", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json, text/plain, */*",
  //         "Content-Type": "application/json",
  //       },

  //       body: JSON.stringify({
  //         gRecaptchaToken: gReCaptchaToken,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((resp) => {
  //         if (resp?.status === "success") {
  //           console.log(resp?.message.score);
  //           console.log("success");
  //           if (!bot) {
  //             setBot(false);
  //           }
  //           return;
  //         } else {
  //           console.log(resp?.message);
  //           //console.log("no oerrd")
  //           setBot(true);
  //           return;
  //         }
  //       });
  //   });
  // }, [bot, executeRecaptcha]);

  useClickOutside(searchRef, () => {
    setResultsOpen(false);
  });

  useEffect(() => {
    // if (executeRecaptcha) {
    load()
      .then((botd) => {
        return botd.detect();
      })
      .then((result) => {
        // console.log(result.bot);
        setBot(result.bot);
        if (!result.bot) {
          //handleRecaptcha();
        }
      })
      .catch((error) => console.error(error));
    // }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///

  if (router.pathname != "/town" && router.pathname.startsWith("/town/")) {
    townProp = JSON.parse(props?.props?.children?.props?.twn);
  }

  function AllEmptyQueryBoundary({
    children,
    fallback,
    resultsOpen,
    searchRef,
  }) {
    const { indexUiState, results, status } = useInstantSearch();

    if (!indexUiState.query) {
      return fallback;
    }
    // console.log(indexUiState.query)
    //   if (indexUiState.query  && results.nbHits === 0) {
    //     return <span className=" animate-bounce  bg-white text-black">...</span>;
    //   }

    if (!results.__isArtificial && results.nbHits === 0) {
      return <>{fallback}</>;
    }
    return (
      <>
        {resultsOpen ? (
          <div
            ref={searchRef}
            className=" absolute  z-50 mt-[2.7rem] flex  w-full  flex-col   space-y-2 divide-y  rounded-b-md  border border-gray-200 bg-white lg:mt-[3.1rem] "
          >
            {children}
          </div>
        ) : null}
      </>
    );
  }

  function CustomTownHits({ setResultsOpen }) {
    const { hits, results, sendEvent } = useHits();
    // console.log("Towns  : "+results.nbHits)
    return (
      <>
        {results.nbHits > 0 ? (
          <div className=" z-50  flex flex-col  bg-white pt-4">
            <h2 className=" mb-1 px-5 ">
              <span className="  text-xs font-semibold uppercase text-gray-600">
                Town
              </span>
            </h2>
            {hits.map((hit) => {
              return (
                <TownHit
                  key={hit.objectID}
                  sendEvent={sendEvent}
                  hit={hit}
                  source="TOWN"
                  setResultsOpen={setResultsOpen}
                />
              );
            })}
          </div>
        ) : null}
      </>
    );
  }

  function CustomListerHits(props) {
    const { hits, results, sendEvent } = useHits(props);
    // console.log(hits)
    return (
      <>
        {results.nbHits > 0 ? (
          <div className=" z-50 flex flex-col rounded-b-none bg-white pb-1 pt-4">
            <h2 className=" mb-1 px-5 ">
              <span className="  text-xs font-semibold uppercase text-gray-600">
                Agent
              </span>
            </h2>
            {hits.map((hit) => {
              return (
                <ListerHit
                  key={hit.objectID}
                  sendEvent={sendEvent}
                  hit={hit}
                  source="TOWN"
                />
              );
            })}
          </div>
        ) : null}
      </>
    );
  }

  function CustomPropertyHits(props) {
    const { hits, results, sendEvent } = useHits(props);
    // console.log("Props : "+results.nbHits)
    return (
      <>
        {results.nbHits > 0 ? (
          <div className=" z-50 flex flex-col rounded-b-md bg-white pb-1 pt-4">
            <h2 className=" mb-1 px-5 ">
              <span className="  text-xs font-semibold uppercase text-gray-600">
                Home
              </span>
            </h2>
            {hits.map((hit) => {
              return (
                <PropertyHit
                  key={hit.objectID}
                  hit={hit}
                  sendEvent={sendEvent}
                  source="TOWN"
                />
              );
            })}
          </div>
        ) : null}
      </>
    );
  }

  function CustomSearchBox({
    bot,
    // handleRecaptcha,
    searchRef,
    setResultsOpen,
  }) {
    const { query, refine, clear } = useSearchBox({
      queryHook,
    });
    const [searchQuery, setSearchQuery] = useState(null);
    return (
      <div className="group flex flex-row">
        <input
          type="search"
          autoComplete="off"
          onFocus={() => {
            setResultsOpen(true);
            // handleRecaptcha();
          }}
          ref={searchRef}
          onChange={(event) => {
            setSearchQuery(event.target.value);
            if (event.target.value.length === 1) {
              // handleRecaptcha();
            }
            if (event.target.value.length >= 3) {
              setResultsOpen(true);
              refine(event.currentTarget.value);

              capture({
                category: "search",
                event: "search_done",
                properties: {
                  searchTerm: event.currentTarget.value,
                },
              });
            }
            if (event.target.value.length === 0) {
              clear();
            }
          }}
          id="town-search"
          disabled={bot}
          defaultValue={townProp.townName}
          placeholder="Enter location..."
          className="flex rounded-l-full border-r-0 border-gray-400 bg-gray-100 px-5 py-2 text-sm text-gray-800 focus:border-gray-400 focus:text-base focus:ring-0 disabled:bg-gray-300    lg:w-64 lg:py-3  lg:text-base"
        ></input>
        <button
          onClick={() => {
            if (searchQuery?.length === 1) {
              // handleRecaptcha();
            }
            if (searchQuery?.length >= 3) {
              refine(searchQuery);

              capture({
                category: "search",
                event: "search_button_click",
                properties: {
                  searchTerm: searchQuery,
                },
              });
            } else {
              capture({
                category: "search",
                event: "search_button_click",
                properties: {
                  searchTerm: false,
                },
              });
            }
          }}
          className=" rounded-r-full border border-l-0 border-gray-400  bg-gray-200 px-3 py-2 text-sm font-medium text-white  lg:px-5 lg:py-3 lg:text-base"
        >
          <FiSearch className=" h-4 w-4 text-gray-700" />
        </button>
      </div>
    );
  }
  let timerId = undefined;
  function queryHook(query, search) {
    if (timerId) {
      clearTimeout(timerId);
    }
    //550 prev
    timerId = setTimeout(() => search(query), 0);
  }

  const isAuth = cookie.get("fast_auth");

  return (
    <>
      <nav
        className={
          " flex  h-16  w-full items-center   justify-between border border-t-0     shadow-none lg:h-20 lg:px-24" +
          (router != null && router.pathname.startsWith("/town/")
            ? "  z-30 border-b-0 px-3  "
            : " sticky top-0 z-20  border-gray-200 bg-white  px-5")
        }
      >
        <div className=" flex flex-row items-center pl-0 lg:pl-2">
          <div
            className={
              " relative flex flex-row justify-center  lg:items-center lg:space-x-12" +
              (router != null && router.pathname.startsWith("/town/")
                ? " space-x-2"
                : " space-x-0")
            }
          >
            <div className=" flex flex-row lg:items-end lg:space-x-2">
              {/* <div className=" lg:bg-ula-prmary flex h-10 w-auto items-center justify-center rounded-full bg-transparent lg:h-12 lg:w-12">
                <div className=" lg:bg-ula-rimary  relative  h-8 w-8 rounded-full bg-transparent ">
                  <Image
                    src="/icon/ula.png"
                    layout="fill"
                    className=" hidden h-5 w-5 object-contain lg:flex"
                    alt=""
                  />
                  <Image
                    src="/icon/ula.png"
                    layout="fill"
                    className=" flex h-5 w-5 object-contain lg:hidden "
                    alt=""
                  />
                </div>
              </div> */}
              {router.pathname.startsWith("/town") ? (
                <></>
              ) : (
                <Link href="/" prefetch={false} passHref>
                  {/* <a className=" relative -mt-1 h-8 w-8 p-5 lg:mt-0 lg:h-10 lg:w-10">
                    <Image
                      src="/icon/ula.png"
                      layout="fill"
                      className=" h-4 w-4 rounded-full object-cover lg:flex"
                      alt="Ula Africa"
                    />
                  </a> */}
                  <a className=" flex h-8 w-8 items-center justify-center rounded-full bg-ula-primary lg:h-10 lg:w-10 lg:bg-ula-primary">
                    <div className=" relative   h-6  w-6 rounded-full bg-ula-primary lg:h-8 lg:w-8 lg:bg-ula-primary ">
                      <Image
                        src="/icon/ula.png"
                        layout="fill"
                        priority
                        className=" flex h-5 w-5 rounded-full object-contain lg:hidden "
                        alt=""
                      />
                    </div>
                  </a>
                </Link>
              )}
              {process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ? (
                <Link href="/" prefetch={false} passHref>
                  <a
                    className="mr-0 mt-0 cursor-pointer font-eastman text-2xl font-medium text-gray-800  lg:mt-0  lg:flex lg:text-3xl "
                    title="Ula Africa"
                  >
                    ula
                  </a>
                </Link>
              ) : (
                <>
                  {process.env.NODE_ENV === "development" ? (
                    <Link href="/" prefetch={false} passHref>
                      <a
                        className="ml-1 mr-0 mt-0 cursor-pointer font-eastman text-2xl font-medium  text-gray-800  lg:mt-0  lg:flex lg:text-3xl   "
                        title="Ula Africa"
                      >
                        ula
                      </a>
                    </Link>
                  ) : (
                    <Link href="/" prefetch={false} passHref>
                      <a
                        className="mr-0 mt-0 cursor-pointer font-eastman text-2xl   text-ula-primary lg:mt-0 lg:text-3xl"
                        title="Ula Africa"
                      >
                        ula
                      </a>
                    </Link>
                  )}
                </>
              )}
            </div>
            {/* {!router.pathname.startsWith("/town") ? ( */}

            <NavigationMenu.Root className=" relative">
              <NavigationMenu.List className="hidden flex-row space-x-10  lg:flex">
                <NavigationMenu.Item>
                  <NavigationMenu.Trigger>
                    <h2 className=" mt-2 cursor-pointer text-base font-medium  text-gray-800 hover:text-ula-primary">
                      Real Estate Agents
                    </h2>
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content>
                    <div className=" absolute left-0 top-10 z-20 flex flex-col  rounded-md border border-gray-300 bg-white px-0 py-3 shadow-xl ">
                      <Link href="/real-estate-agency-near-me" passHref>
                        <a>
                          <h3 className=" w-full cursor-pointer rounded-none px-6 py-3 text-gray-800 hover:bg-gray-200">
                            Find an agent
                          </h3>
                        </a>
                      </Link>
                      <Link href="/hodari" passHref>
                        <a>
                          <div className="flex w-64  flex-row items-center space-x-4 px-6 py-2 hover:bg-gray-200 ">
                            <h3 className=" cursor-pointer rounded-none px-0 py-2 text-gray-800 hover:bg-gray-200">
                              Join Hodari Agent
                            </h3>
                            {/* <span className=" rounded-full bg-ula-primary px-3 py-1 text-xs uppercase text-gray-100">
                              New
                            </span> */}
                          </div>
                        </a>
                      </Link>
                    </div>
                    <NavigationMenu.Link />
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.Trigger>
                    <h2 className=" mt-2 cursor-pointer text-base font-medium text-gray-800 hover:text-ula-primary">
                      Tools
                    </h2>
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content>
                    <div className=" absolute left-0 top-10 z-20 flex flex-col  rounded-md border border-gray-300 bg-white px-0 py-3 shadow-xl ">
                      <Link href="/calculators/rent-budget-calculator" passHref>
                        <a>
                          <h3 className=" w-full cursor-pointer rounded-none px-6 py-3 text-gray-800 hover:bg-gray-200">
                            Rent Budget Calculator
                          </h3>
                        </a>
                      </Link>
                      <Link href="/calculators/mortgage-calculator" passHref>
                        <a>
                          <div className="flex w-64  flex-row items-center space-x-4 px-6 py-2 hover:bg-gray-200 ">
                            <h3 className=" cursor-pointer rounded-none px-0 py-2 text-gray-800 hover:bg-gray-200">
                              Mortgage Calculator
                            </h3>
                          </div>
                        </a>
                      </Link>
                    </div>
                    <NavigationMenu.Link />
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <Link href="/blog" passHref>
                  <a>
                    <h2 className=" mt-2 cursor-pointer text-base font-medium text-gray-800 hover:text-ula-primary">
                      Blog
                    </h2>
                  </a>
                </Link>

                <NavigationMenu.Item>
                  <NavigationMenu.Trigger />
                  <NavigationMenu.Content>
                    <NavigationMenu.Sub>
                      <NavigationMenu.List />
                      <NavigationMenu.Viewport />
                    </NavigationMenu.Sub>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Indicator className="z-[1] flex h-[10px] items-end justify-center">
                  <div className="relative left-0 top-1 h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px] bg-gray-300" />
                </NavigationMenu.Indicator>
              </NavigationMenu.List>

              <NavigationMenu.Viewport />
            </NavigationMenu.Root>
            {/* <div className=" hidden flex-row space-x-6  lg:flex">
              <Link href="/real-estate-agency-near-me" passHref>
                <a>
                  <h2 className=" mt-2 cursor-pointer text-base text-gray-700">
                    Agent Finder
                  </h2>
                </a>
              </Link>
              <Link href="/blog" passHref>
                <a>
                  <h2 className=" mt-2 cursor-pointer text-base text-gray-700">
                    Blog
                  </h2>
                </a>
              </Link>
            </div> */}
            {/* ) : null} */}
          </div>
        </div>

        {/* ///search */}

        {router != null && router.pathname.startsWith("/town/") ? (
          <div className=" flex h-auto   flex-col  items-center justify-between bg-white lg:h-12 lg:flex-row">
            <InstantSearch
              searchClient={searchClient}
              indexName={process.env.NEXT_PUBLIC_PROPERTIES_INDEX}
            >
              <div className=" relative     flex w-full flex-col rounded-md border-0 border-gray-900 lg:w-full">
                <CustomSearchBox
                  bot={bot}
                  // handleRecaptcha={handleRecaptcha}
                  searchRef={searchRef}
                  setResultsOpen={setResultsOpen}
                />

                <AllEmptyQueryBoundary
                  fallback={null}
                  resultsOpen={resultsOpen}
                  searchRef={searchRef}
                >
                  <Index indexName={process.env.NEXT_PUBLIC_TOWNS_INDEX}>
                    <Configure hitsPerPage={3} />
                    <CustomTownHits setResultsOpen={setResultsOpen} />
                  </Index>

                  <Index indexName={process.env.NEXT_PUBLIC_LISTERS_INDEX}>
                    <Configure hitsPerPage={3} />
                    <CustomListerHits />
                  </Index>

                  <Index indexName={process.env.NEXT_PUBLIC_PROPERTIES_INDEX}>
                    <Configure hitsPerPage={3} />
                    <CustomPropertyHits />
                  </Index>
                </AllEmptyQueryBoundary>
              </div>
            </InstantSearch>
          </div>
        ) : null}

        <div className="flex flex-row items-center space-x-4 lg:space-x-8">
          <div className=" flex flex-row items-center space-x-4">
            {router.pathname != "/ula-app" ? (
              <Link href="/ula-app" passHref>
                <a>
                  <h2
                    className={
                      "hidden cursor-pointer rounded-full   bg-ula-primary px-8 py-2 font-medium    text-gray-100 lg:flex "
                      //+
                      // (router.pathname.startsWith("/town")
                      //</a>?
                      // " bg-ula-primary text-gray-100 "
                      //: "  bg-ula-background transition-colors duration-300 ease-in-out hover:bg-ula-primary hover:text-gray-100")
                    }
                  >
                    Ula mobile app
                  </h2>
                </a>
              </Link>
            ) : null}
            {/* 
            {(!router.pathname.startsWith("/town") &&
              router.pathname !== "/list-a-property" &&
              !user &&
              !loadingUser) ||
            claims?.manager ? (
              <Link href="/list-a-property" passHref>
                <a>
                  <h2 className="hidden cursor-pointer lg:flex">
                    List your property
                  </h2>
                </a>
              </Link>
            ) : null} */}

            {claims?.user ? (
              <div
                onClick={() => {
                  router.push("/account/favorites").then(() => {
                    setPopOpen(false);
                  });
                }}
                className=" hidden cursor-pointer rounded-md px-6 py-2 hover:bg-ula-primary hover:text-white lg:flex"
              >
                Saved Homes
              </div>
            ) : null}
          </div>

          {!user && !loadingUser && router.pathname !== "/list-a-property" ? (
            <span
              onClick={
                bot
                  ? () => {}
                  : () => {
                      setOpen(!open);
                      setActive(1);
                    }
              }
              className=" hidden cursor-pointer rounded-md border-0 border-gray-400 px-0 py-2 font-medium text-gray-800 lg:flex "
            >
              Signup
            </span>
          ) : null}

          {!user && !loadingUser && router.pathname !== "/list-a-property" ? (
            <button
              onClick={
                bot
                  ? () => {}
                  : () => {
                      setOpen(!open);
                      setActive(0);
                    }
              }
              className=" font-medium text-gray-800  "
            >
              Login
            </button>
          ) : null}

          {user && isAuth && !loadingUser ? (
            <Popover.Root
              open={popOpen}
              onOpenChange={(open) => {
                if (open) {
                  setPopOpen(true);
                } else {
                  setPopOpen(false);
                }
              }}
            >
              <Popover.Trigger>
                <Popover.Anchor>
                  <div
                    className={
                      " relative hidden h-8 w-8 cursor-pointer  items-center  justify-center rounded-full bg-blue-100 lg:flex lg:h-10 lg:w-10" +
                      (allUnread > 0 && claims.user
                        ? "  border-2 border-pink-500"
                        : " border border-gray-300")
                    }
                  >
                    {user.photoURL && user.photoURL !== "https://default" ? (
                      <Image
                        src={user.photoURL}
                        className=" h-full w-full rounded-full  object-cover"
                        priority
                        layout="fill"
                        alt="User Photo"
                      />
                    ) : (
                      <CgProfile className=" h-4 w-4 text-ula-primary lg:h-5 lg:w-5" />
                    )}
                  </div>
                </Popover.Anchor>
              </Popover.Trigger>

              <Popover.Portal>
                <Popover.Content
                  align="end"
                  alignOffset={-80}
                  className=" z-20  rounded-b-md bg-white  "
                >
                  <Popover.Arrow className=" fill-gray-200" />
                  <div className=" flex flex-col space-y-3 divide-y rounded-md border border-gray-400 py-6 shadow-lg">
                    <div className=" flex flex-col  space-y-3 px-6 pb-2">
                      <div className=" flex flex-row items-center space-x-3">
                        <div className=" relative flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 bg-blue-100 lg:h-8 lg:w-8">
                          {user.photoURL &&
                          user.photoURL !== "https://default" ? (
                            <Image
                              src={user.photoURL}
                              className=" h-full w-full rounded-full object-cover"
                              priority
                              layout="fill"
                              alt="User Photo"
                            />
                          ) : (
                            <CgProfile className=" h-4 w-4 text-ula-primary" />
                          )}
                        </div>
                        <div className=" flex flex-col items-start space-y-0 text-sm text-gray-800">
                          <span className=" cursor-default select-none text-base font-medium">
                            {user?.displayName}
                          </span>
                          <div className=" flex flex-col items-start space-y-1">
                            <span className=" select-none text-sm text-gray-600">
                              {user?.email}
                            </span>
                            {/* {user?.emailVerified ? (
                                <span className=" w-auto cursor-default rounded-full bg-blue-100 px-3 py-1 text-xs font-semibold text-ula-secondary">
                                  Email Verified
                                </span>
                              ) : (
                                <span
                                  onClick={() => {
                                    if (!resetLinkSent) {
                                      sendEmailVerification(auth.currentUser)
                                        .then(() => {
                                          setSendingResetLink(false);
                                          setResetLinkSent(true);
                                        })
                                        .catch((error) => {
                                          const errorCode = error.code;
                                          const errorMessage = error.message;
                                          //   console.log(errorCode);
                                          setSendingResetLink(false);
                                          setResetLinkSent(false);
                                        });
                                    }
                                  }}
                                  onMouseEnter={() => {
                                    setEmailVerifiedText(
                                      "Send Verification Link"
                                    );
                                  }}
                                  onMouseLeave={() => {
                                    setEmailVerifiedText("Email Unverified");
                                  }}
                                  className={
                                    " w-auto  rounded-full bg-yellow-100 px-3 py-1 text-xs font-semibold text-yellow-800 " +
                                    (resetLinkSent
                                      ? " cursor-default"
                                      : " cursor-pointer hover:bg-blue-100 hover:text-blue-700")
                                  }
                                >
                                  {resetLinkSent
                                    ? "Link Sent. Check mail app"
                                    : `${emailVerifiedText}`}
                                </span>
                              )} */}
                          </div>
                        </div>
                      </div>
                      {/* {!claims.user?  <span className=" text-sm text-gray-600">
                          Your account has 1/10 active properties
                        </span>:null} */}
                      {/* {!claims.user?  <span className=" underline underline-offset-4 cursor-pointer decoration-gray-500 hover:decoration-gray-600 font-medium text-sm">
                          Upgrade
                        </span>: null} */}
                    </div>
                    <div className=" flex flex-col pt-2 ">
                      {
                        claims.manager ? (
                          <div
                            onClick={() => {
                              router.push("/lister/dashboard").then(() => {
                                setPopOpen(false);
                              });
                            }}
                            className=" cursor-pointer px-6 py-2 text-sm text-gray-800 hover:bg-gray-200"
                          >
                            Dashboard
                          </div>
                        ) : (
                          <></>
                        )
                        // <div onClick={()=>{
                        //   router.push('/account/profile').then(()=>{
                        //     setPopOpen(false)
                        //   })
                        // }}  className=" text-gray-800 text-sm cursor-pointer hover:bg-gray-200 px-6 py-2">
                        //   Profile
                        // </div>
                      }
                      {/* {claims.user ? (
                        <div className=" mb-1 flex w-full  px-6 py-1 text-sm">
                          <span
                            onClick={() => {
                              router.push("/account/rewards").then(() => {
                                setPopOpen(false);
                              });
                            }}
                            className=" w-full cursor-pointer rounded-full bg-[#80FF80]  px-5 py-1 text-center font-medium text-zinc-900 "
                          >
                            Invite agent and get Kshs 2000
                          </span>
                        </div>
                      ) : null} */}
                      {claims.user ? (
                        <div
                          onClick={() => {
                            router.push("/account/favorites").then(() => {
                              setPopOpen(false);
                            });
                          }}
                          className=" cursor-pointer px-6 py-2 text-sm text-gray-800 hover:bg-gray-200"
                        >
                          Saved Homes
                        </div>
                      ) : null}
                      {claims.user ? (
                        <div
                          onClick={() => {
                            router.push("/account/messages").then(() => {
                              setPopOpen(false);
                            });
                          }}
                          className=" flex cursor-pointer flex-row items-center justify-between px-6 py-2 text-sm text-gray-800 hover:bg-gray-200"
                        >
                          <span>Messages</span>
                          {claims.user && allUnread > 0 ? (
                            <span className=" flex h-5 w-5 items-center justify-center rounded-full bg-pink-500 text-xs font-semibold text-white">
                              {allUnread}
                            </span>
                          ) : null}
                        </div>
                      ) : null}

                      <div
                        onClick={() => {
                          signOut().then(() => {
                            router.push("/");
                          });
                        }}
                        className=" cursor-pointer px-6 py-2 text-sm text-gray-800 hover:bg-gray-200"
                      >
                        Sign out
                      </div>
                    </div>
                  </div>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          ) : null}

          <div
            onClick={() => {
              setOpenNav(!openNav);
            }}
            className="  h-7 w-7 relative flex   items-center lg:hidden lg:h-8 lg:w-8"
          >
            <AiOutlineMenu className=" h-5 w-5 text-gray-800 " />
            {claims?.user && allUnread > 0 ? (
              <span className=" absolute -right-1 -top-1 z-10 flex h-4 w-4 items-center justify-center rounded-full bg-pink-500 text-xs font-semibold text-white lg:right-0 lg:top-0">
                {allUnread}
              </span>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
}

export default MainNav;
